export default [
  {
    title: "Dashboard",
    route: "home",
    icon: "HomeIcon",
    img: require("../../assets/images/icons/dashboard-icon.svg"),
    activeIcon: require("../../assets/images/icons/dashboard-white-icon.svg"),
  },

  {
    title: "User Details",
    route: "user-management",
    icon: "UsersIcon",
    img: require("../../assets/images/icons/users-icon.svg"),
    activeIcon: require("../../assets/images/icons/users-white-icon.svg"),
  },
  // {
  //   title: "Fishing Guides",
  //   route: "fish-guide",
  //   icon: "AnchorIcon",
  //   img: require("../../assets/images/icons/anchor-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/fishing-guide-white-icon.svg"),
  // },
  // {
  //   title: "Fishing Spots",
  //   route: "fish-spot",
  //   icon: "",
  //   img: require("../../assets/images/icons/boat-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/fishing-spot-white.svg"),
  // },
  // {
  //   title: "Fishing Gears",
  //   route: "fish-gear",
  //   icon: "",
  //   img: require("../../assets/images/icons/gear-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/fishing-gear-white.svg"),
  // },
  // {
  //   title: "Order Listing",
  //   route: "/",
  //   icon: "ListIcon",
  //   img: require("../../assets/images/icons/list-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/list-white-icon.svg"),
  // },
  // {
  //   title: "Monthly Submissions",
  //   route: "monthly-submissions",
  //   icon: "ImageIcon",
  //   img: require("../../assets/images/icons/monthly-subscription-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/monthly-subscription-white.svg"),
  // },
  // {
  //   title: "Rating & Reviews",
  //   route: "rating-reviews",
  //   icon: "StarIcon",
  //   img: require("../../assets/images/icons/rating-star-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/star-white.svg"),
  // },
  // {
  //   title: "Reports",
  //   icon: "StarIcon",
  //   img: require("../../assets/images/icons/report-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/report-icon.svg"),
  //   children: [
  //     {
  //       title: "Subscription Earning",
  //       route: "report-subscription",
  //     },
  //     {
  //       title: "Transaction Earning",
  //       route: "report-transaction",
  //     },
  //     {
  //       title: "SOS",
  //       route: "report-sos",
  //     },
  //   ],
  // },
  // {
  //   title: "Settings",
  //   route: "/",
  //   icon: "",
  //   img: require("../../assets/images/icons/settings-icon.svg"),
  // },
  // {
  //   title: "Moderation",
  //   route: "moderation",
  //   icon: "",
  //   img: require("../../assets/images/icons/moderation-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/moderation-white.svg"),
  // },
  // {
  //   title: "Community Posts",
  //   route: "community-post",
  //   icon: "",
  //   img: require("../../assets/images/icons/posts-icon.svg"),
  //   activeIcon: require("../../assets/images/icons/community-post-white.svg"),
  // },
];
